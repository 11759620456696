





















































import { Component, Watch } from 'vue-property-decorator';

import FilterComponent from '@/components/form/FilterComponent';
import StructureFilter from '@/components/form/StructureFilter.vue';

import { ProductEnumName, SensibilityEnumName, CmrEnumName } from '@modules/chemical-risk/types/enum';

@Component({
  components: { StructureFilter },
})
export default class SynthesisFilter extends FilterComponent {
  filters = {
    product: '',
    old: '',
    cmr: '',
    affectation: '',
    sensibility: '',
    productStatus: '',
  };

  productStatusEnum = ProductEnumName;
  sensibilityStatusEnum = SensibilityEnumName;
  cmrStatusEnum = CmrEnumName;

  filter() {
    this.send();
  }
}

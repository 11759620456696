















import moment from 'moment';
import _ from 'lodash';
import Routing from 'fos-routing';
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PaginatedData } from '@/types/paginatedData';

import Layout from '@/layouts/LayoutDefault.vue';
import { HelpActionInterface, PrintActionInterface } from '@/types/breadcrumb';

import ProductFolder from '@modules/chemical-risk/entities/ProductFolder';
import CheFolder from '@modules/chemical-risk/entities/CheFolder';
import HazardStatement from '@modules/chemical-risk/entities/HazardStatement';
import { AnnexVIInformation, AnnexVISubstance } from '@modules/chemical-risk/types/types';

import SynthesisTable from '../../components/SynthesisTable.vue';
import SynthesisFilter from '../../components/form/SynthesisFilter.vue';

const cheModule = namespace('cheRisk');

@Component({
  components: {
    Layout,
    SynthesisFilter,
    SynthesisTable,
  },
})
export default class SynthesisView extends Vue {
  @cheModule.Action('loadProductsFolder')
  loadProductsFolder!: (payload: any) => PaginatedData<ProductFolder>;

  @cheModule.Getter('getActiveCheFolder')
  activeCheFolder!: CheFolder;

  @cheModule.Getter('getHazardStatementByReference')
  getHazardStatementByReference!: (reference: string) => HazardStatement;

  @cheModule.Getter('getCmrLabel')
  getCMRLabel!: (ids: Array<number>) => string;

  @cheModule.Getter('getSensibilityLabel')
  getSensibilityLabel!: (ids: Array<number>) => string;

  @cheModule.Action('loadAnnexVI')
  loadAnnexVI!: () => any;

  @cheModule.Action('getAnnexVISubstances')
  getAnnexVI!: (productSheetUuid: string) => Promise<AnnexVISubstance | null>;

  @cheModule.Action('getAnnexVIInformation')
  getAnnexVIInformation!: () => any;

  products: ProductFolder[] = [];

  loading: boolean = true;
  page: number = 1;
  total: number = 1;
  filters: any = {
    product: '',
    affectation: '',
    cmr: '',
    sensibility: '',
    old: false,
    productStatus: '',
    orderColumn: '',
    order: '',
  };
  annexVISubstances: AnnexVISubstance[] = [];
  annexVIInformation: AnnexVIInformation = {
    date: '',
    version: '',
  };
  helpMessage: string = '';

  get helpAction(): HelpActionInterface | null {
    return this.helpMessage ? {
      content: this.helpMessage,
    } : null;
  }

  get elements(): Array<ProductFolder> {
    return this.products.filter(p => (this.filters.product === '' || p.productSheet.label.toLowerCase().includes(this.filters.product.toLowerCase()))
      && (this.filters.affectation === '' || this.filters.affectation.length === 0 || _.intersection(p.structures.map(s => s.id), this.filters.affectation).length > 0)
      && (this.filters.cmr === '' || (this.filters.cmr === 'all' && (this.getCMRLabel(p.productSheet.hazardStatements).includes('(a)') || this.getCMRLabel(p.productSheet.hazardStatements).includes('(s)'))) || this.getCMRLabel(p.productSheet.hazardStatements).includes(this.filters.cmr))
      && (this.filters.sensibility === '' || (this.filters.sensibility === 'all' && (this.getSensibilityLabel(p.productSheet.hazardStatements).includes('(c)') || this.getSensibilityLabel(p.productSheet.hazardStatements).includes('(r)'))) || (this.filters.sensibility !== '' && this.getSensibilityLabel(p.productSheet.hazardStatements).includes(this.filters.sensibility)))
      && (this.filters.productStatus === '' || p.status === this.filters.productStatus)
      && (!this.filters.old || moment(p.productSheet.year, 'DD/MM/YYYY').isBefore(moment().subtract(5, 'year'))));
  }

  get printAction(): PrintActionInterface | null {
    return {
      href: this.downloadPDF(),
    };
  }

  async load(page: number = 1) {
    this.loading = true;
    const data = await this.loadProductsFolder({ page, filters: this.filters, limit: 0 });

    this.products = data.items;
    this.page = data.meta.currentPage;
    this.total = data.meta.total;

    await this.syncAnnexVI();

    this.loading = false;
  }

  async syncAnnexVI() {
    this.annexVISubstances = await this.loadAnnexVI();
    this.annexVIInformation = await this.getAnnexVIInformation();

    this.annexVISubstances.forEach((aVI: AnnexVISubstance) => {
      this.initSubstanceInfo(aVI);
    });

    const productSheetToSearch: string[] = [];
    this.products.forEach((p) => {
      const productSheetUuid = p.productSheet.uuid;
      const aVI = this.annexVISubstances.find(s => s.uuid === productSheetUuid);
      if (aVI === undefined) {
        productSheetToSearch.push(productSheetUuid);
      }
    });

    productSheetToSearch.reduce((p, productSheetUuid) => p.then(() => this.getAnnexVI(productSheetUuid)
      .then((substance) => {
        if (substance) {
          this.initSubstanceInfo(substance);
          this.annexVISubstances.push(substance);
        }
      })), Promise.resolve());
  }

  initSubstanceInfo(aVI: AnnexVISubstance) {
    aVI.substances.forEach((s: any) => {
      const hazardsList: number[] = [];
      s.classificationHazardStatementCodes.split('\n').forEach((h: any) => {
        const hazardStatement = this.getHazardStatementByReference(h);
        if (hazardStatement) {
          hazardsList.push(hazardStatement.id);
        }
      });

      Vue.set(s, 'cmrLabel', this.getCMRLabel(hazardsList));
      Vue.set(s, 'sensibilityLabel', this.getSensibilityLabel(hazardsList));
    });
  }

  downloadPDF() {
    return Routing.generate('api_download_synthesis_che_folder', {
      id: this.activeCheFolder.id,
      filters: this.filters,
    });
  }

  public mounted() {
    this.load(this.page);
  }

  async created() {
    const page = await this.pageService.get('fds');
    this.helpMessage = page.help;
  }
}
